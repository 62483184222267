<!-- Tabs.vue -->
<template>
  <div class="tabs-container">
    <ul class="nav nav-tabs">
      <li class="nav-item" v-for="(tab, index) in tabs" :key="index">
        <a
          class="nav-link"
          :class="{ active: selectedTab === index }"
          href="#"
          @click.prevent="selectTab(index)"
        >
          {{ tab.label }}
        </a>
      </li>
    </ul>

    <div class="tab-content">
      <!-- Render the selected tab content -->
      <slot :name="selectedTabName"></slot>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedTab: 0, // Track the currently selected tab
    };
  },
  props: {
    tabs: {
      type: Array,
      required: true,
      // Each tab should have a label and a name for the slot
      validator(value) {
        return value.every((tab) => tab.label && tab.name);
      },
    },
  },
  computed: {
    selectedTabName() {
      return this.tabs[this.selectedTab].name;
    },
  },
  methods: {
    selectTab(index) {
      this.selectedTab = index;
    },
  },
};
</script>

<style scoped>
.tabs-container {
  margin-bottom: 1rem;
}
.nav-tabs {
  margin-bottom: 1rem;
}
.nav-link.active {
  background-color: #007bff;
  color: white;
}
</style>
