<template>
  <div>
    <div class="list-group settings">
      <div class="gitlab-user-info list-group-item">
        <!-- <h3>{{ $t("page_settings--title") }}</h3> -->

          <div>
              <div class="mt-3">
                <div class="col mb-3">
                  <label for="currentPassword" class="form-label"
                    >Current Password</label
                  >
                  <input
                    type="password"
                    class="form-control"
                    v-model="currentPassword"
                    id="currentPassword"
                    aria-describedby="currentPasswordHelp"
                  />
                  <div
                    v-if="shouldShowCPasswordInput"
                    class="auth__notifier"
                    :class="{ shake: !$v.currentPassword.error }"
                  >
                    >Password must be at least 8 characters long.
                  </div>
                </div>
              </div>

              <div class="mt-3">
                <div class="col mb-3">
                  <label for="newPassword" class="form-label"
                    >New Password</label
                  >
                  <input
                    type="password"
                    class="form-control"
                    v-model="newPassword"
                    id="newPassword"
                    aria-describedby="newPasswordHelp"
                  />
                  <div
                    v-if="shouldShowNPasswordInput"
                    class="auth__notifier"
                    :class="{ shake: !$v.newPassword.error }"
                  >
                    >Password must be at least 8 characters long.
                  </div>
                </div>
              </div>

              <div class="mt-3">
                <div class="col mb-3">
                  <label for="confirmNewPassword" class="form-label"
                    >Confirm New Password</label
                  >
                  <input
                    type="password"
                    class="form-control"
                    v-model="confirmPassword"
                    id="confirmNewPassword"
                    aria-describedby="confirmNewPasswordHelp"
                  />
                  <div
                    v-if="shouldShowCNPasswordInput"
                    class="auth__notifier"
                    :class="{ shake: !$v.confirmPassword.error }"
                  >
                    >Should be same as Password.
                  </div>
                </div>
              </div>
              <div class="mt-3">
                <div class="col mb-3">
                  <button
                    @click="handleResetPassword"
                    class="ed-btn"
                    :disabled="isLoading"
                  >
                    <span v-if="!isLoading">Reset Password</span>
                    <span
                      v-else
                      class="spinner-border spinner-border-sm ml-2"
                      :style="{ opacity: isLoading ? 1 : 0 }"
                    ></span>
                  </button>
                </div>
              </div>
            </div>
      </div>

      <!-- <div class="widgetSettings list-group-item">
        <h3>{{ $t("page_settings--widgetTitle") }}</h3>

        <div class="list-group widget-settings">
          <div class="form-check form-switch widget-each">
            <input
              class="form-check-input"
              type="checkbox"
              id="exploreWidgetSwitch"
              v-model="widget.showExplore"
              disabled
            />
            <label class="form-check-label" for="exploreWidgetSwitch"
              >Explore Widget</label
            >
          </div>
          <div class="form-check form-switch widget-each">
            <input
              class="form-check-input"
              type="checkbox"
              id="WolframWidgetSwitch"
              v-model="widget.showWolfram"
            />
            <label class="form-check-label" for="WolframWidgetSwitch"
              >Wolfram Widget</label
            >
          </div>
          <div class="form-check form-switch widget-each">
            <input
              class="form-check-input"
              type="checkbox"
              id="WikidataWidgetSwitch"
              v-model="widget.showWikidata"
            />
            <label class="form-check-label" for="WikidataWidgetSwitch"
              >Wikidata Widget</label
            >
          </div>
        </div>
        <button
          type="button"
          class="btn btn-success"
          @click="updateWidgetSettings"
        >
          {{ $t("global_save") }}
        </button>
      </div> -->
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import mergeNotNull from '@/plugins/mergeNotNull';
import { required, minLength, sameAs } from 'vuelidate/lib/validators';

export default {
  name: 'SecuritySettings',
  data() {
    return {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
      isLoading: false,
      isSubmitLoading: false,
    };
  },
  validations: {
    currentPassword: {
      required,
      minLength: minLength(8),
    },
    newPassword: {
      required,
      minLength: minLength(8),
    },
    confirmPassword: {
      required,
      minLength: minLength(8),
      sameAsPassword: sameAs('newPassword'),
    },
  },
  computed: {
    ...mapGetters({
      user: 'auth/authUser',
    }),
    shouldShowCPasswordInput() {
      return (
        this.$v.currentPassword.required && !this.$v.currentPassword.minLength
      );
    },
    shouldShowNPasswordInput() {
      return this.$v.newPassword.required && !this.$v.newPassword.minLength;
    },
    shouldShowCNPasswordInput() {
      return (
        this.$v.confirmPassword.required
        && !this.$v.confirmPassword.sameAsPassword
      );
    },
  },
  async mounted() {
    this.unsubscribe = this.$store.subscribe(async (mutation) => {
      if (mutation.type === 'setUserInfo') {
        await this.$store.dispatch('getUserSettingsFromServer');
        const settingsFromState = this.$store.state.settings;
        const currentSettings = { social: this.social, widget: this.widget };
        const mergedSettings = mergeNotNull(currentSettings, settingsFromState);

        this.social = mergedSettings.social;
        this.widget = mergedSettings.widget;
      }
    });
  },
  methods: {
    ...mapActions('auth', ['resetPasswordstatus']),
    async handleResetPassword() {
      this.isLoading = true;
      const { currentPassword, newPassword, confirmPassword } = this;

      try {
        await this.resetPasswordstatus({
          currentPassword,
          password: newPassword,
          confirmPassword,
        });
        this.isLoading = false;
      } catch (error) {
        console.error(error);
      }
    },
  },
  components: {},
  beforeDestroy() {
    this.unsubscribe();
  },
};
</script>

<style lang="scss" scoped>
</style>
