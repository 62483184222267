<template>
  <div class="container-fluid px-4 py-5">
    <Tabs :tabs="tabs">
      <!-- Tab 1 content -->
      <template #profile>
        <div>
          <!-- <h3>Security Settings</h3> -->
          <!-- Your general settings form goes here -->
          <EditProfile/>
        </div>
      </template>
      <!-- Tab 2 content -->
      <template #social>
        <div>
          <!-- <h3>Security Settings</h3> -->
          <!-- Your general settings form goes here -->
          <SocialSettingsWidget/>

        </div>
      </template>
      <template #security>
        <div>
          <!-- <h3>Security Settings</h3> -->
          <!-- Your general settings form goes here -->
          <SecurityWidget/>
        </div>
      </template>
      <template #accessibility>
        <div>
          <!-- <h3>Security Settings</h3> -->
          <!-- Your general settings form goes here -->
          <Accessibility />
        </div>
      </template>
    </Tabs>
  </div>
</template>

<script>
import SocialSettingsWidget from '@/common/widgets/SocialSettingsWidget/SocialSettingsWidget.vue';
import SecurityWidget from '@/common/widgets/SecurityWidget/SecurityWidget.vue';
import Tabs from '@/common/components/Tabs.vue';
import EditProfile from '@/common/components/EditProfile.vue';
import Accessibility from '@/common/components/accessibility/Index.vue';

export default {
  name: 'Settings',
  metaInfo: {
    title: 'Settings',
  },
  data() {
    return {
      tabs: [
        { label: 'Profile', name: 'profile' },
        { label: 'Social', name: 'social' },
        { label: 'Security', name: 'security' },
        { label: 'Accessibility', name: 'accessibility' },
      ],
    };
  },
  components: {
    // MetaView,
    Tabs,
    SocialSettingsWidget,
    SecurityWidget,
    Accessibility,
    EditProfile,
  },

  computed: {
    showMeta() {
      if (this.$store.state.wikiInformation) {
        const wikiInfoLength = this.$store.state.wikiInformation.length;
        if (wikiInfoLength > 0) {
          return true;
        }
      }
      if (
        this.$store.state.selectedNode
        && this.$store.state.selectedNode.nodeLabel
      ) {
        return true;
      }

      if (
        this.$store.state.wolframInformation
        && this.$store.state.wolframInformation.answer
      ) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang="scss">
.metaview {
  z-index: 10;
}
</style>
